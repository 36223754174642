<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit  }">
  <b-form @submit.stop.prevent="handleSubmit(addEssay)">
  <main
    class="grid grid-cols-1 md:grid-cols-12 gap-4"
  >
    <div class="col-span-8">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <input-default
            :label="
              $t('sectionPharmaceuticals.essaysPharmaceuticals.testDescription')
            "
            :rules="{required:true,max:50}"
            max="50"
            v-model="descricao"
          />
        </div>
      </div>
      <footer
        class="w-full flex items-center justify-start gap-5 my-10"
      >
      <Button
        class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
        buttonText="Confirmar"
        type="submit"
      />
        <Button
          class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
          buttonText="Cancelar"
          :onClick="() => $emit('showFormCreate',false)"
        />
      </footer>
    </div>
  </main>
  </b-form>
</ValidationObserver>
</template>

<script type="module">
import InputDefault from "@core/components/commons/inputs/InputDefault.vue";
import Button from "@core/components/commons/button/Button";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate"; 
export default {
  name: "EssayForm",
  components: {
    InputDefault,
    Button,
    ValidationObserver,
    BForm,
  },
  data() {
    return {
      descricao:null,
    }
  },
  props: {
    formAction: {
      type: String,
    },
    value: {
      type: Object,
      required:true,
    }
  },
  methods: {
    addEssay () {
        this.value.FormaFarmaceuticaEnsaio.push({descricao:this.descricao,excluido:false});
        this.$emit('showFormCreate',false)
    }
  },
};
</script>
